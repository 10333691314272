<template>
  <div>
    <v-card flat>
      <v-card-title
        ><h3 class="grey--text">
          <v-icon class="mr-2" large>mdi-note-text-outline</v-icon>Invoice Card
        </h3>
        <v-spacer></v-spacer
        ><v-btn text outlined class="ml-3" color="success" @click="get_inv()"
          ><v-icon>mdi-refresh</v-icon>Refresh</v-btn
        >
      </v-card-title>
      <v-card-text>
        <v-divider class="mb-3"></v-divider>
        <v-text-field
          label="Search"
          prepend-icon="mdi-magnify"
          v-model="search"
        ></v-text-field>

        <div v-if="ready">
          <v-data-table
            dense
            :search="search"
            :items="invoices"
            :headers="headers"
            :items-per-page="items_per_page"
          >
            <template v-slot:item.action="{ item }">
              <div class="d-flex">
                <PayablesModal :invoice="item" />
                <AddPaymentModal
                  :invoice="item"
                  v-if="item.invoice_status !== 'Paid'"
                />
                <v-btn class="mr-2 ml-2" v-else disabled icon
                  ><v-icon>mdi-cash-multiple</v-icon></v-btn
                >
                <confirm-invoice-print-modal :item="item" />
                <v-btn
                  v-if="user.access_level >= 4"
                  icon
                  @click="del_invoice(item)"
                  color="red"
                  class="mr-2"
                  :loading="loading"
                  ><v-icon class="mr-2">mdi-delete</v-icon></v-btn
                >
              </div>
            </template>
            <!-- PURPOSE -->
            <template v-slot:item.invoice_status="{ item }">
              <span
                v-if="item.invoice_status == 'Waiting for Payment'"
                class="red--text font-weight-black"
              >
                PENDING PAYMENT
              </span>
              <span
                v-if="item.invoice_status == 'Paid'"
                class="green--text font-weight-black"
              >
                PAID
              </span>
              <span v-else>
                <span
                  v-if="item.invoice_status == 'With Balance'"
                  class="warning--text font-weight-black"
                >
                  WITH BALANCE
                </span>
              </span>
            </template>

            <template v-slot:item.is_invoice="{ item }">
              <v-chip color="blue" class="white--text" v-if="item.is_invoice"
                >Invoice</v-chip
              >
              <v-chip color="red" class="white--text" v-else>Bill</v-chip>
            </template>
          </v-data-table>
        </div>
        <div v-else class="d-flex justify-center">
          <h2>Data Table is Loading..</h2>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import AddPaymentModal from "./addPaymentModal.vue";
import PayablesModal from "./payablesModal.vue";
import ConfirmInvoicePrintModal from "./confirmInvoicePrintModal.vue";
export default {
  props: ["customer_id"],
  data() {
    return {
      display_price_modal: false,
      loading: false,
      dialog: true,
      search: "",
      items_per_page: 5,
      ready: false,
      show_add_invoice: false,
      headers: [
        { text: "", value: "action", width: 100 },
        { text: "ID", value: "id", width: 30 },
        {
          text: "Invoice Code",
          value: "invoice_code",
          width: 100,
        },
        { text: "Balance", value: "balance" },
        { text: "Total Amount", value: "total_amount", width: 30 },
        { text: "Date", value: "invoice_date", width: 100 },
        { text: "Status", value: "invoice_status" },
        { text: "Purpose", value: "purpose" },
        { text: "Type", value: "is_invoice" },
      ],
    };
  },
  computed: {
    customer() {
      var index = this.customers.findIndex((a) => a.id == this.customer_id);
      return this.customers[index];
    },
    ...mapGetters({
      user: "auth/user",
      invoices: "invoice/invoices",
      payments: "payment/payments",
      default_server_url: "auth/default_server_url",
      customers: "customer/customers",
    }),
  },
  methods: {
    async get_inv() {
      this.ready = false;
      await this.get_invoices(this.customer_id);
      this.ready = true;
    },
    async del_invoice(item) {
      this.loading = true;
      let y = window.confirm("Are you sure you want to delete this invoice?");
      if (y) {
        var x = window.confirm(
          "WARNING! Please take note of the quantities of all the items inside this invoice. Add them back to the item's quantity for they are not reversible. DO YOU UNDERSTAND?"
        );
        if (!x) this.loading = false;
        if (x) {
          await this.delete_invoice(item);
          this.loading = false;
          alert("Successfully deleted an invoice");
        }
      } else {
        this.loading = false;
      }
    },
    close(type) {
      if (type == "add_invoice") return (this.show_add_invoice = false);
      this.$emit("close");
    },
    change_view_type() {
      if (this.view_type == "Quotes") {
        // remove
      }
    },

    formatted_warranty(invoice) {
      let duration = invoice.invoiceable.warranty.warranty_duration;
      let count = invoice.invoiceable.warranty.warranty_count;
      return count + " " + duration;
    },
    warranty_expiration(invoice) {
      let invoice_date = moment(invoice.invoice_date);
      let expiration = invoice_date.add(
        invoice.invoiceable.warranty.warranty_count,
        invoice.invoiceable.warranty.warranty_duration
      );
      return expiration.format("YYYY-MM-DD");
    },
    ...mapActions({
      get_invoices: "invoice/get_invoices",
      get_payments: "payment/get_payments",
      delete_invoice: "invoice/delete_invoice",
    }),
  },
  created() {
    this.get_inv();
  },
  components: {
    AddPaymentModal,

    PayablesModal,
    ConfirmInvoicePrintModal,
  },
};
</script>

<style lang="scss" scoped></style>
